export function mapProductForCart(matterportTagInfoToBeMapped) {
	if (!matterportTagInfoToBeMapped) return;

	const mappedProduct = {
		attributes: [
			{
				name: matterportTagInfoToBeMapped.label
					? matterportTagInfoToBeMapped.label.split('::')[2]
					: 'No label',
				value: '125'
			}
		],
		category_id: '5f9c49a084867d22272c7954',
		category_ids: ['5f9c49a084867d22272c7954'],
		category_name: 'Idol',
		category_slug: 'item',
		code: '',
		cost_price: 0,
		date_created: '2020-10-29T18:51:20.307Z',
		date_sale_from: '2020-10-30T18:30:00.000Z',
		date_sale_to: '2020-12-30T18:30:00.000Z',
		date_stock_expected: '2020-12-08T18:30:00.000Z',
		date_updated: '2020-10-30T17:16:53.299Z',
		description: matterportTagInfoToBeMapped.description || 'No description',
		dimensions: { length: 0, width: 0, height: 0 },
		discontinued: false,
		enabled: true,
		id: matterportTagInfoToBeMapped.label
			? matterportTagInfoToBeMapped.label.split('::')[0]
			: null,
		images: [
			{
				alt: '',
				filename: 'necc.png',
				id: '5f9b0fbc2045b8185619cfb1',
				position: 99,
				url: matterportTagInfoToBeMapped.label
					? matterportTagInfoToBeMapped.label.split('::')[3]
					: null
			}
		],
		meta_description: 'fwefwgfewgwegew',
		meta_title: 'defw',
		name: matterportTagInfoToBeMapped.label
			? matterportTagInfoToBeMapped.label.split('::')[2]
			: '',
		on_sale: true,
		options: [],
		path: `/item/${matterportTagInfoToBeMapped.label.replace(/ /g, '')}`,
		position: null,
		price: matterportTagInfoToBeMapped.label
			? matterportTagInfoToBeMapped.label.split('::')[1]
			: 0,
		prices: [],
		quantity_inc: 1,
		quantity_min: 1,
		regular_price: matterportTagInfoToBeMapped.label
			? matterportTagInfoToBeMapped.label.split('::')[1]
			: 0,
		related_product_ids: [],
		sale_price: matterportTagInfoToBeMapped.label
			? matterportTagInfoToBeMapped.label.split('::')[1]
			: 0,
		sku: '22',
		slug: matterportTagInfoToBeMapped.label.replace(/ /g, ''),
		stock_backorder: true,
		stock_preorder: true,
		stock_quantity: 21,
		stock_status: 'available',
		stock_tracking: true,
		tags: [],
		tax_class: '',
		url: `/item/${matterportTagInfoToBeMapped.label.replace(/ /g, '')}`,
		variable: false,
		variants: [],
		weight: 111
	};

	// console.log('final mappedProduct = ', mappedProduct);
	return mappedProduct;
}
