import React from "react";
import Iframe from "react-iframe";
import logo from "./logo.svg";
import "./App.css";
import { pointToString } from "./point";
import { findSelectedTagFromArray } from "./findSelectedTagFromArray";
import { mapProductForCart } from "./mapProductForCart";

import { slide as Menu } from "react-burger-menu";

class App1 extends React.Component {
  // modelSid = "WaBeHSBPFxV"   // Raj kelkar
  modelSid = "VavyUHG166x";

  sdk;
  // const modelSid = "QQmxUMyYbGh"; //Sai Electronics

  constructor(props) {
    super(props);
    this.delayBeforeShow = 1000;
    this.handleClick = this.handleClick.bind(this);

    this.state = {
      mattertags: [],
      matterLink:
        "https://my.matterport.com/show?m=" +
        this.modelSid +
        "&hr=0&play=1&title=0&qs=1",
    };
  }

  componentDidMount() {
    var button = document.getElementById("button123");
    var text = document.getElementById("text123");
    const jsFiddleKey = "de404569a57d4ba4ada3fe92bb03c31c";

    const productInfoPopup = document.getElementById("product-details");

    const sdkVersion = "3.5";

    let productForCart = {};

    let matterportTags;

    var iframe = document.getElementById("showcase_iframe");
    let matterTags = [];
    console.log("ifram = ", iframe);
    var isTag = false;

    var that = this;

    window.MP_SDK.connect(iframe, jsFiddleKey, sdkVersion).then(async function (
      theSdk
    ) {
      that.sdk = theSdk;
      console.log("SDK Connected!");

      fetch("./mattertags.json", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (myJson) {
          console.log("Custom Tags:");

          console.log(myJson);
          that.setState({
            mattertags: myJson,
          });
        });

      var poseCache;
      that.sdk.Camera.pose.subscribe(function (pose) {
        poseCache = pose;
      });

      // sdk.Mattertag.add([
      //   {
      //     id:"1",
      //     label: "New tag 1",
      //     description: "This tag was added through the Matterport SDK 1",
      //     anchorPosition: {x: 1.2668004818991414, y: 1.0468360671557375, z: 2.7857338883754115},
      //     stemVector: {
      //       // make the Mattertag stick straight up and make it 0.30 meters (~1 foot) tall
      //       x: 0,
      //       y: 0.3,
      //       z: 0,
      //     },
      //     color: {
      //       // blue disc
      //       r: 0.0,
      //       g: 0.0,
      //       b: 1.0,
      //     },
      //     floorId: 0, // optional, if not specified the sdk will provide an estimate of the floor id for the anchor position provided.
      //   },
      // ]);

      // sdk.Mattertag.add([
      //   {
      //     id:"2",
      //     label: "New tag 2",
      //     description: "This tag was added through the Matterport SDK 2",
      //     anchorPosition: {x: 1.08764211940327, y: 1.2475593328829582, z: 4.367999255598245},
      //     stemVector: {
      //       // make the Mattertag stick straight up and make it 0.30 meters (~1 foot) tall
      //       x: 0,
      //       y: 0.3,
      //       z: 0,
      //     },
      //     color: {
      //       // blue disc
      //       r: 0.0,
      //       g: 0.0,
      //       b: 1.0,
      //     },
      //     floorId: 0, // optional, if not specified the sdk will provide an estimate of the floor id for the anchor position provided.
      //   },{
      //     id:"4",
      //     label: "New tag 3",
      //     description: "This tag was added through the Matterport SDK 2",
      //     anchorPosition: {x: -2.806175576543038, y: 1.1968629984629873, z: 13.504105028930534},
      //     stemVector: {
      //       // make the Mattertag stick straight up and make it 0.30 meters (~1 foot) tall
      //       x: 0,
      //       y: 0.3,
      //       z: 0,
      //     },
      //     color: {
      //       // blue disc
      //       r: 0.0,
      //       g: 0.0,
      //       b: 1.0,
      //     },
      //     floorId: 0, // optional, if not specified the sdk will provide an estimate of the floor id for the anchor position provided.
      //   },{
      //     id:"5",
      //     label: "White Ganesh Sculpture",
      //     description: "Rs. 5,00,000",
      //     anchorPosition: {x: -7.595192301269029, y: 0.8663272045395324, z: 15.278618804403365},
      //     stemVector: {
      //       // make the Mattertag stick straight up and make it 0.30 meters (~1 foot) tall
      //       x: 0,
      //       y: 0.3,
      //       z: 0,
      //     },
      //     color: {
      //       // blue disc
      //       r: 0.0,
      //       g: 0.0,
      //       b: 1.0,
      //     },
      //     floorId: 0, // optional, if not specified the sdk will provide an estimate of the floor id for the anchor position provided.
      //   }
      // ]);

      const callback = (model) => {
        for (let i = 0; i < matterportTags.length; i++) {
          if (matterportTags[i].sid === model) {
            console.log("My model loaded for mattertag:", matterportTags[i]);

            // matterportTags[i].price = mattertags[i].label
            // 	? mattertags[i].label.split(':')[1].split(':')[0]
            // 	: null;

            // matterportTags[i].label = mattertags[i].label
            // 	? mattertags[i].label.split(':')[1].split(':')[1]
            // 	: null;

            productForCart = mapProductForCart(matterportTags[i]);

            console.log("Product Description:", productForCart);

            // console.log('product pointed = ', productForCart);
            isTag = true;
            productInfoPopup.style.display = "block";
            // updatePopupWithProductDetails(productForCart);
            // set product info to product info popup
            document.getElementById(
              "prod-title"
            ).innerHTML = `<b>Title</b> : ${productForCart.name}`;
            document.getElementById(
              "prod-img"
            ).innerHTML = `<b>Title</b> : ${productForCart.images[0].url}`;
            document.getElementById(
              "prod-description"
            ).innerHTML = `<b>Description</b> : ${productForCart.description}`;
            document.getElementById(
              "prod-price"
            ).innerHTML = `<b>Price</b> : ${productForCart.price}`;

            document.getElementById(
              "prod-img"
            ).src = `${productForCart.images[0].url}`;
          }
        }
      };

      // Start listening to the event.
      that.sdk.on(that.sdk.Mattertag.Event.CLICK, callback);

      const removeBillboardTimer = setInterval(() => {
        that.sdk.Mattertag.getData()
          .then((mattertags) => {
            console.log("Fetching info for tag mattertags = ", mattertags);

            matterportTags = mattertags;

            // Mattertag data retreival complete.
            if (mattertags.length > 0) {
              for (let i = 0; i < mattertags.length; i++) {
                const noBillboardTag = mattertags[i];

                that.sdk.Mattertag.preventAction(noBillboardTag.sid, {
                  opening: true,
                });
              }

              clearInterval(removeBillboardTimer);

              console.log("MatterportTags Added");

              for (let i = 0; i < matterportTags.length; i++) {
                // delete matterportTags[i].sid;
                delete matterportTags[i].parsedDescription;
                delete matterportTags[i].mediaSrc;

                delete matterportTags[i].mediaSrc;
                delete matterportTags[i].mediaType;
                delete matterportTags[i].media;
                delete matterportTags[i].anchorNormal;
                delete matterportTags[i].enabled;
                delete matterportTags[i].floorId;
                delete matterportTags[i].floorIndex;
                delete matterportTags[i].floorInfo;
              }

              console.log(JSON.stringify(matterportTags));
            }
          })
          .catch((error) => {
            // Mattertag data retrieval error.
            console.log("Error while fetching MetaTags", error);
          });
      }, 1000);

      var intersectionCache;
      that.sdk.Pointer.intersection.subscribe(function (intersection) {
        // console.log("intersection = ",intersection.position.x, intersection.position.y,intersection.position.z);
        if (intersection.object === "intersectedobject.tag") {
          console.log("***********found tags no get tag info");
          const tagInfo = findSelectedTagFromArray(
            matterTags,
            intersection.position
          );
          isTag = true;
          console.log(intersection);
        } else {
          isTag = false;
        }

        intersectionCache = intersection;
        intersectionCache.time = new Date().getTime();
        //   setTimeout(() => {
        button.style.display = "none";
        buttonDisplayed = false;
        //   }, 5000)
      });

      var delayBeforeShow = 1000;
      var buttonDisplayed = false;
      setInterval(() => {
        if (!intersectionCache || !poseCache) {
          return;
        }

        const nextShow = intersectionCache.time + delayBeforeShow;
        if (new Date().getTime() > nextShow) {
          if (buttonDisplayed) {
            return;
          }

          var size = {
            w: iframe.clientWidth,
            h: iframe.clientHeight,
          };

          var coord = that.sdk.Conversion.worldToScreen(
            intersectionCache.position,
            poseCache,
            size
          );

          console.log("Size:", coord);
          button.style.left = `${coord.x}px`;
          button.style.top = `${coord.y}px`;
          // button.style.left = `0px`;
          // button.style.top = `0px`;

          // button.style.display = 'block';
          // buttonDisplayed = true;
        }
      }, 16);

      button.addEventListener("click", function () {
        console.log("Button click", intersectionCache.position);

        var currentTagPos = pointToString(intersectionCache.position);
        var foundTag = findSelectedTagFromArray(matterTags, currentTagPos);
        //   text.innerHTML = `position: ${pointToString(intersectionCache.position)}\nnormal: ${pointToString(intersectionCache.normal)}\nfloorId: ${intersectionCache.floorId}`;
        //   button.style.display = 'none';
        iframe.focus();
      });
    });
  }

  handleClick(e, item) {
    e.preventDefault();
    console.log("The link was clicked.");

    this.sdk.Mattertag.navigateToTag(
      "B1Kqak4APNT",
      this.sdk.Mattertag.Transition.FLY
    );

    this.setState({ matterLink: item.link+"&title=0&qs=1&help=0&hr=0&play=1&dh=0&nozoom=1" });
  }

  render() {
    // console.log('123tag state = ', this.state.isTag);
    document.title = 'Sandvirp Immersive';
    return (
      <div className="right" id="outer-container">
        <img src="logo.png" className="logo"></img>
        {/* <div style={{ height: 300 }}> */}
        <Menu
          right
          noOverlay
          width={250}
          isOpen={true}
          pageWrapId={"page-wrap"}
          outerContainerId={"outer-container"}
        >
          {this.state.mattertags &&
            this.state.mattertags.length > 0 &&
            this.state.mattertags.map((item) => (
              <a
                onClick={(e) => this.handleClick(e, item)}
                className="menu-item"
                href=""
              >
                {item.label}
              </a>
            ))}

            <div className="get-in-touch"> <a href="https://wa.me/919922418121">Get In Touch</a></div>
        </Menu>
        <main id="page-wrap">
          <iframe
            src={
              // "https://my.matterport.com/show?m=QQmxUMyYbGh&hr=0&play=1&title=0&qs=1"
              // "https://my.matterport.com/show?m=YQDZH33Ho6K&hr=0&play=1&title=0&qs=1"
              this.state.matterLink
            }
            id="showcase_iframe"
            // allowFullScreen={true}
            // width={150}
            // height={150}
            // id="myId"
            // className="myClassname"
            // display="initial"
            // position="relative"
          />
          {/* </div> */}
          <div id="text123" style={{ height: 100, position: "relative" }}>
            This is details
          </div>
          <button
            style={{
              height: 50,
              width: 200,
              // position: "relative",
              // float: "right",
              // right: 50,
              // bottom: 55,
            }}
            id="button123"
          >
            Get Product Details
          </button>
        </main>
      </div>
    );
  }
}

export default App1;
