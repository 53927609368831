export function findSelectedTagFromArray (matterTags111, findTag) {
    console.log('findSelectedTagFromArray called', findTag);
    // hardedcoded values as matterTags111 is not present
    const matterTags = [
        {
            anchorPosition : 
                {x: 1.08764211940327, y: 1.2475593328829582, z: 4.367999255598245},
        },
        {
            anchorPosition : 
            {x: 1.2668004818991414, y: 1.0468360671557375, z: 2.7857338883754115},
        },

    ]
    const foundTag = matterTags.find((tag, index) => {
        // if(tag.anchorPosition) {
            // var x = getNearValue((tag.anchorPosition.x).toString());
            // var y = getNearValue((tag.anchorPosition.y).toString());
            // var z = getNearValue((tag.anchorPosition.z).toString());

            // var xFindTag = getNearValue((findTag.x).toString());
            // var yFindTag = getNearValue((findTag.y).toString());
            // var zFindTag = getNearValue((findTag.z).toString());
            const mTagX = getFloatValue(tag.anchorPosition.x);
            const cursorX = getFloatValue(findTag.x);
            const mTagY = getFloatValue(tag.anchorPosition.y);
            const cursorY = getFloatValue(findTag.y);
            const isXRange = checkIfXValueInRange(mTagX,cursorX );
            const isYRange = checkIfYValueInRange( mTagY, cursorY)
            if(isXRange && isYRange){
                console.log('**********finally found tag at =', index, matterTags[index]);
                return true;
            }
            return false;
        // }
    });
    console.log('found prodtc tga = ', foundTag);
    return foundTag;
}

function checkIfXValueInRange (mTagX, cursorX) {
    var maxX = mTagX + 0.05;
    var minX = mTagX - 0.05;
    console.log('x to compare = maxX',maxX, ' min x = ', minX , "cursorX = ", cursorX);
    if(minX <= cursorX && maxX >= cursorX) {
        return true;
    }
    return false;
}

function checkIfYValueInRange (mTagY, cursorY) {
    const yValueToCheck = mTagY + 0.3;
    var maxY = yValueToCheck + 0.05;
    var minY = yValueToCheck - 0.05;
    console.log('y to compare = maxy',maxY, ' min x = ', minY , "cursorY = ", cursorY);
    if(minY <= cursorY && maxY >= cursorY) {
        return true;
    }
    return false;
}

// if value 5.4567876545678 then formatted value 5.45
function getFloatValue(valueToFormat) {

    var splitStr = valueToFormat.toString().split(".");
    var afterDecimalStr = splitStr[1].substring(0,2);
    return parseFloat(splitStr[0]+"."+afterDecimalStr);
}